<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <div class="relative bg-white overflow-hidden">
    <Header />
    <div class="relative pt-6 pb-16 sm:pb-24 lg:pb-32">
      <main class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
        <div class="lg:grid lg:grid-cols-12 lg:gap-8">
          <div
            class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left"
          >
            <h1>
              <span
                class="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base"
                >now open</span
              >
              <span
                class="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl"
              >
                <span class="block text-gray-900">Grants 2021</span>
                <span class="block text-tsred">for cancer research</span>
              </span>
            </h1>
            <p
              class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl"
            >
              The goal of the Research Program is to provide funding for
              innovative projects expected to lead to future funding from other
              peer-reviewed sources.
            </p>
            <div
              class="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0"
            >
              <a href="#registration-form"
                class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-tsred hover:bg-tsred-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tsred-light"
              >
                Apply
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {Popover, PopoverButton, PopoverPanel} from "@headlessui/vue"
import {MenuIcon, XIcon} from "@heroicons/vue/outline"
import Header from "@/components/Header"

const navigation = [
  {name: "Product", href: "#"},
  {name: "Features", href: "#"},
  {name: "Marketplace", href: "#"},
  {name: "Company", href: "#"},
]

export default {
  name: 'Hero',
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    MenuIcon,
    XIcon,
    Header,
  },
  setup() {
    return {
      navigation,
    }
  },
}
</script>
