<template>
  <div class="home">
    <Hero/>
    <Info/>
    <RegistrationForm />
    <Footer />
  </div>
</template>

<script>
import Hero from '@/components/Hero'
import Info from '@/components/Info'
import RegistrationForm from '@/components/RegistrationForm'
import Footer from '@/components/Footer'

export default {
  name: 'Home',
  components: {
    Hero,
    Info,
    RegistrationForm,
    Footer
  }
}
</script>
