<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          sky: colors.sky,
          teal: colors.teal,
          rose: colors.rose,
        },
      },
    },
  }
  ```
-->
<template>
  <div class="bg-gray-50">
    <Container class="py-10">
      <div
        class="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px"
      >
        <div
          v-for="(action, actionIdx) in actions"
          :key="action.title"
          :class="[
            actionIdx === 0
              ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
              : '',
            actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
            actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
            actionIdx === actions.length - 1
              ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
              : '',
            'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
          ]"
        >
          <div>
            <span
              :class="[
                action.iconBackground,
                action.iconForeground,
                'rounded-lg inline-flex p-3 ring-4 ring-white',
              ]"
            >
              <component :is="action.icon" class="h-6 w-6" aria-hidden="true" />
            </span>
          </div>
          <div class="mt-8">
            <h3 class="text-lg font-medium">
              <!-- <a :href="action.href" class="focus:outline-none"> -->
              <!-- Extend touch target to entire panel -->
              <span class="absolute inset-0" aria-hidden="true" />
              {{ action.title }}
              <!-- </a> -->
            </h3>
            <p class="mt-2 text-sm text-gray-500">
              {{ action.desc }}
            </p>
          </div>
          <!-- <span class="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
        </svg>
      </span> -->
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import Container from "@/components/Container"

import {
  AcademicCapIcon,
  BadgeCheckIcon,
  CashIcon,
  ClockIcon,
  RefreshIcon,
  PuzzleIcon,
  CurrencyDollarIcon,
  ReceiptRefundIcon,
  CalendarIcon,
  DocumentTextIcon,
  UsersIcon,
} from "@heroicons/vue/outline"

const actions = [
  {
    title: "Who are we?",
    desc:
      "TumorShot LLC is an innovative company with a modern approach to cancer treatment, focusing on advanced immunotherapy.",
    href: "#",
    icon: UsersIcon,
    // iconForeground: 'text-teal-700',
    // iconBackground: 'bg-teal-50',
    iconForeground: 'text-tsyellow',
    iconBackground: 'bg-tscream'
  },
  {
    title: "What do we fund?",
    desc: `We have been funding grants that demonstrate potential for impact on cancer basic research, medicinal chemistry, molecular targeting or prevention.`,
    href: "#",
    icon: PuzzleIcon,
    // iconForeground: 'text-purple-700',
    // iconBackground: 'bg-purple-50',
    iconForeground: 'text-tsred-light',
    iconBackground: 'bg-tscream'
  },
  {
    title: "Grant amount?",
    desc:
      "Up to $US15,000 for a project that is innovative, beneficial and synergistic in the field of cancer treatment or prevention.",
    href: "#",
    icon: CurrencyDollarIcon,
    // iconForeground: 'text-sky-700',
    // iconBackground: 'bg-sky-50',
    iconForeground: 'text-tsyellow',
    iconBackground: 'bg-tscream'
  },
  {
    title: "How to apply?",
    desc:
      "Please register using the form below and follow the further instructions, once you register you will be redirected to a page with more details.",
    href: "#",
    icon: DocumentTextIcon,
    // iconForeground: "text-yellow-700",
    // iconBackground: "bg-yellow-50",
    iconForeground: 'text-tsyellow',
    iconBackground: 'bg-tscream'
  },
  {
    title: "How it works?",
    desc:
      "The grants selection process utilizes a research proposal internal review system, all the applications.",
    href: "#",
    icon: RefreshIcon,
    // iconForeground: "text-rose-700",
    // iconBackground: "bg-rose-50",
    iconForeground: 'text-tsyellow',
    iconBackground: 'bg-tscream'
  },
  {
    title: "Important dates?",
    desc:
      "Deadline for your registartion and sending the necessary documents is October 15, 2021; notifications will be sent on October 30, 2021.",
    href: "#",
    icon: CalendarIcon,
    // iconForeground: "text-indigo-700",
    // iconBackground: "bg-indigo-50",
    iconForeground: 'text-tsyellow',
    iconBackground: 'bg-tscream'
  },
]

export default {
  name: 'Info',
  components: {
    Container,
  },
  setup() {
    return {
      actions,
    }
  },
}
</script>
