<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->

<template>
  <Container class="py-10" id="registration-form">
    <form class="space-y-8 ">
      <div class="space-y-8  sm:space-y-5">
        <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div>
            <h3 class="text-4xl pb-4 font-bold font-medium text-gray-900">
              Registration Form
            </h3>
          </div>
          <div class="space-y-6 sm:space-y-5">
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
              <label
                for="first-name"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                First name
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="registrationData.firstName"
                  type="text"
                  name="first-name"
                  id="first-name"
                  autocomplete="given-name"
                  class="max-w-lg block w-full shadow-sm focus:ring-tsred-light focus:border-tsred-dark sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="last-name"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Last name
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="registrationData.lastName"
                  type="text"
                  name="last-name"
                  id="last-name"
                  autocomplete="family-name"
                  class="max-w-lg block w-full shadow-sm focus:ring-tsred-light focus:border-tsred-dark sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="last-name"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Institution name
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="registrationData.institutionName"
                  type="text"
                  name="last-name"
                  id="last-name"
                  autocomplete="family-name"
                  class="max-w-lg block w-full shadow-sm focus:ring-tsred-light focus:border-tsred-dark sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="city"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                City
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="registrationData.city"
                  type="text"
                  name="city"
                  id="city"
                  class="max-w-lg block w-full shadow-sm focus:ring-tsred-light focus:border-tsred-dark sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="state"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                State
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="registrationData.state"
                  type="text"
                  name="state"
                  id="state"
                  class="max-w-lg block w-full shadow-sm focus:ring-tsred-light focus:border-tsred-dark sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="email"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                E-mail
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="registrationData.email"
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  class="max-w-lg block w-full shadow-sm focus:ring-tsred-light focus:border-tsred-dark sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="email"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                E-mail confirmation
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="registrationData.email2"
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  class="max-w-lg block w-full shadow-sm focus:ring-tsred-light focus:border-tsred-dark sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div class="sm:border-t sm:border-gray-200 sm:pt-5">
              <Toggle
                class="mb-5"
                :modelValue="registrationData.acceptTerms"
                @update:modelValue="registrationData.acceptTerms = $event"
                >I hereby accept the <router-link class="underline" to="terms-of-use">Terms of Use</router-link> which I have read and
                understood. I hereby expressly acknowledge and agree that
                submitting an application does not entitle me to any rights,
                payments and/or compensation and does not constitute a legal
                title to a grant.</Toggle
              >
              <Toggle
                class="mb-5"
                :modelValue="registrationData.understandPrivacyPolicy"
                @update:modelValue="
                  registrationData.understandPrivacyPolicy = $event
                "
                >I have read and understood the <router-link class="underline" to="privacy-policy">Privacy Policy</router-link>.</Toggle
              >
              <Toggle
                :modelValue="registrationData.receiveUpdates"
                @update:modelValue="registrationData.receiveUpdates = $event"
                text="I don’t mind occasionally receiving information about related news from you."
              />
            </div>
          </div>
        </div>
      </div>

      <div class="pt-5 pb-10">
        <div class="flex justify-start">
          <Button
            :progress="registrationForm.progress"
            :success="registrationForm.success"
            :error="registrationForm.error"
            @click="submitForm"
            class=""
          >
            Submit application
          </Button>
        </div>
      </div>
    </form>
  </Container>
</template>

<script>
import Container from "@/components/Container"
import Toggle from "@/components/Toggle"
import Button from "@/components/Button"
import {fn} from "@/globals"
import {httpsCallable} from "firebase/functions"
const register = httpsCallable(fn, "register")

export default {
  name: "RegistrationForm",
  components: {
    Container,
    Toggle,
    Button,
  },
  data() {
    return {
      registrationData: {
        firstName: "",
        lastName: "",
        institutionName: "",
        city: "",
        state: "",
        email: "",
        email2: "",
        acceptTerms: false,
        understandPrivacyPolicy: false,
        receiveUpdates: false,
      },
      registrationForm: {
        progress: false,
        success: false,
        error: false,
      },
    }
  },
  setup() {},
  methods: {
    async submitForm(e) {
      e.preventDefault()

      console.log(
        typeof Object.values(this.registrationData)[0],
        typeof Object.values(this.registrationData)[7]
      )

      // same as in functions/registration/register.js
      let allFieldsFilled = Object.values(this.registrationData).every(
        (x) =>
          (typeof x === "string" && x !== null && x !== "") ||
          typeof x === "boolean"
      )
      let emailsMatch =
        this.registrationData.email === this.registrationData.email2

      let emailValid = this.validateEmail(this.registrationData.email)

      let termsAccepted = this.registrationData.acceptTerms && this.registrationData.understandPrivacyPolicy

      let formValid = allFieldsFilled && emailsMatch && emailValid && termsAccepted

      console.log("form validator:", allFieldsFilled, emailsMatch, formValid)

      if (!formValid) {
        this.registrationForm.error = true
        await new Promise((resolve) => setTimeout(resolve, 1000))
        this.registrationForm.error = false
      } else {
        this.registrationForm.progress = true

        let response = await register({formData: this.registrationData})

        console.log(response)

        if (response.data.status == "success") {
          console.log("registration success")
          this.registrationForm.progress = false
          this.registrationForm.success = true
          await new Promise((resolve) => setTimeout(resolve, 1000))
          this.$router.push("proposal-overview")
        } else if (response.data.status == "error") {
          console.log("registration error")
          this.registrationForm.error = true
        }
      }
    },
    validateEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
  },
}
</script>
