<template>
  <div class="relative bg-white overflow-hidden">
    <div class="relative pt-6 pb-16 sm:pb-24 lg:pb-32">
      <div>
        <nav
          class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
          aria-label="Global"
        >
          <div class="flex items-center flex-1">
            <div class="flex items-center justify-between w-full md:w-auto">
              <a href="/">
                <span class="sr-only">TumorShot</span>
                <img
                  class="h-8 w-52 sm:h-10"
                  src="../assets/tumorshot-logo.svg"
                  alt=""
                />
              </a>
            </div>
            <!-- <div class="hidden md:block md:ml-10 md:space-x-10">
              <a v-for="item in navigation" :key="item.name" :href="item.href" class="font-medium text-gray-500 hover:text-gray-900">{{ item.name }}</a>
            </div> -->
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {}
  },
}
</script>
