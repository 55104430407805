import {initializeApp} from "firebase/app"
import {getFunctions, connectFunctionsEmulator} from "firebase/functions"

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}

const app = initializeApp(firebaseConfig)

var fbsFn = getFunctions(app)
if (process.env.VUE_APP_ENVIRONMENT == 'local_dev') {
  console.log('using emulated functions')
  connectFunctionsEmulator(fbsFn, 'localhost', 5001)
}

export const fn = fbsFn