<!-- This example requires Tailwind CSS v2.0+ -->
<template>
<div class='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
      <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Container',
}
</script>

<style scoped lang="less">

</style>



