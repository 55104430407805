<template>
  <div
    class="inline-flex justify-center items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer"
    :class="[
      {'bg-green-500 hover:bg-green-700 focus:ring-green-300': success},
      {'bg-red-500 hover:bg-red-700 focus:ring-red-300': error},
      {'bg-tsred hover:bg-tsred-dark focus:ring-tsred-light ': !success && !error},
    ]"
  >
    <!-- progress animation -->
    <svg
      v-if="progress"
      class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>

    <!-- success tick -->
    <svg
      v-if="success"
      class="-ml-1 mr-3 h-5 w-5 text-white"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="icon-tick"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        transform="translate(-0.000000, 2.949331)"
      >
        <polygon
          id="Path-Copy"
          fill="currentColor"
          class="opacity-75"
          fill-rule="nonzero"
          points="21.456026 0 8.41467527 13.0413507 2.5160164 7.15666884 0 9.67268516 8.41467527 18.1013374 24 2.51601639"
        ></polygon>
      </g>
    </svg>

    <!-- error X -->
    <svg
      v-if="error"
      class="-ml-1 mr-3 h-5 w-5 text-white"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Artboard-Copy"
          fill="currentColor"
          class="opacity-75"
          fill-rule="nonzero"
        >
          <g id="Group" transform="translate(2.942341, 2.949331)">
            <polygon
              id="Path-Copy"
              transform="translate(9.057659, 9.050669) rotate(-360.000000) translate(-9.057659, -9.050669) "
              points="15.5713441 0 -4.84539778e-13 15.5713441 2.52999338 18.1013374 18.1153181 2.51601639"
            ></polygon>
            <polygon
              id="Path-Copy-Copy"
              transform="translate(9.057659, 9.050669) scale(-1, 1) rotate(-360.000000) translate(-9.057659, -9.050669) "
              points="15.5713441 0 -4.84539778e-13 15.5713441 2.52999338 18.1013374 18.1153181 2.51601639"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    progress: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="less"></style>
